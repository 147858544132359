import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { launchStackAsync } from "../../../redux/slices/accountSlice";
import "./AddAccountModal.scss";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import InfoModal from "../info-modal/InfoModal";
import infoFilled from "../../../assets/img/icons/ep-info-filled.svg";

const AccountDetailStep = ({
  accountDetails,
  setAccountDetails,
  handleStepTwoModal,
  externalId,
  regionList,
  nextStep,
  backStep,
  setShowLoader,
  showLoader,
}) => {
  const dispatch = useDispatch();

  const DATA_SCANNING_FREQUENCY = [
    { label: "Daily", value: "rate(1 day)" },
    { label: "Weekly", value: "rate(7 days)" },
    { label: "Monthly", value: "rate(30 days)" },
  ];

  const [enableBtn, setEnableBtn] = useState(false);
  const [lunchStackModal, setLunchStackModal] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("us-east-1");
  const [dataScanFrequency, setDataScanFrequency] = useState(
    DATA_SCANNING_FREQUENCY[2].value
  );

  // Formik
  const formik = useFormik({
    initialValues: {
      accountId: accountDetails.accountId,
      accountDescription: accountDetails.accountDescription,
      externalId: externalId,
      awsRoleARN: accountDetails.awsRoleARN,
      dataScanFrequency: dataScanFrequency,
    },
    validationSchema: Yup.object().shape({
      accountId: Yup.string()
        .max(12, "Account Id should be 12 digit")
        .min(12, "Account Id should be 12 digit")
        .matches("^[0-9]", "Account Id should be numeric.")
        .required("Please enter valid Account Id"),
      externalId: Yup.string().required("Please enter External Id."),
      awsRoleARN: Yup.string().required("Please enter Role Arn."),
    }),
    onSubmit: (values) => {
      setAccountDetails({ ...values });
      nextStep();
    },
  });

  const launchStackHandler = async () => {
    setShowLoader(true);
    const resp = await dispatch(
      launchStackAsync({
        id: formik.values.accountId,
        region: selectedRegion,
        dataScanFrequency: dataScanFrequency,
      })
    );
    if (resp && resp.payload?.data && !resp.payload.err) {
      setShowLoader(false);
      window.open(resp.payload?.data);
    } else {
      setShowLoader(false);
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message ||
          `Failed to launch stack for account ${formik.values.accountId}. Please check the Account Id.`
      );
    }
  };

  useEffect(() => {
    setEnableBtn(
      accountDetails.accountId &&
        accountDetails.externalId &&
        accountDetails.awsRoleARN
    );
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-body">
          <label className="label">Account Id</label>
          <span className="mandatory-icon">*</span>
          <div className="row">
            <div className="col col-lg-12">
              <input
                type="number"
                id="accountId"
                placeholder="12-Digit AWS Account Id"
                name="accountId"
                maxLength={"12"}
                minLength={"12"}
                className="form-control"
                onChange={(e) => {
                  if (e.target.value.length === 13) {
                    return;
                  }
                  formik.setFieldValue("accountId", e.target.value?.trim());
                  setEnableBtn(true);
                }}
                value={formik.values.accountId}
                onBlur={formik.handleBlur}
              />
              {formik.errors.accountId && formik.touched.accountId && (
                <div className="error-message">{formik.errors.accountId}</div>
              )}
            </div>
          </div>
          <label className="label">Account Description</label>
          {/* <span className="mandatory-icon">*</span> */}
          <div className="row mt-2">
            <div className="col col-lg-12">
              <textarea
                className="form-control"
                placeholder="Account Description"
                name="accountDescription"
                id="accountDescription"
                onChange={(e) => {
                  formik.setFieldValue("accountDescription", e.target.value);
                }}
                value={formik.values.accountDescription}
              ></textarea>
            </div>
          </div>
          <label className="label">External ID</label>
          <span className="mandatory-icon">*</span>
          <div className="row">
            <div className="col col-lg-12">
              <input
                type="text"
                id="externalId"
                placeholder="External Id"
                name="externalId"
                className="form-control"
                onChange={(e) => {
                  formik.setFieldValue("externalId", e.target.value?.trim());
                  setEnableBtn(true);
                }}
                value={formik.values.externalId}
                onBlur={formik.handleBlur}
              />
              {formik.errors.externalId && formik.touched.externalId && (
                <div className="error-message">{formik.errors.externalId}</div>
              )}
            </div>
            <div className="col-lg-12">
              <label className="label">
                The External ID has been auto generated for you. You can change
                the ID to a custom ID.
              </label>
            </div>
          </div>
          <label className="label">Region</label>
          <div className="row">
            <div className="col col-lg-12">
              <div className="row">
                <div className="col col-lg-12">
                  <select
                    className="form-control"
                    value={selectedRegion}
                    onChange={(value) => {
                      setSelectedRegion(value.value);
                    }}
                  >
                    <option disabled>Select Region</option>
                    <option value={"us-east-1"}>us-east-1</option>
                    {/* {regionList.map((region, index) => (
                      <option key={index} value={region.value}>
                        {region.value}
                      </option>
                    ))} */}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <label className="label">Data Scanning Frequency</label>
          <div className="row">
            <div className="col col-lg-12">
              <div className="row">
                <div className="col col-lg-12">
                  <select
                    className="form-control"
                    value={formik.values.dataScanFrequency}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      setDataScanFrequency(event.target.value);
                      formik.setFieldValue(
                        "dataScanFrequency",
                        event.target.value
                      );
                    }}
                  >
                    <option disabled>Select Data Scanning Frequency</option>
                    {DATA_SCANNING_FREQUENCY.map((frequency, index) => (
                      <option key={index} value={frequency.value}>
                        {frequency.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {showLoader && (
            <img
              src="static/img/loader-circle.svg"
              className="modal-loading-img"
            ></img>
          )}
          <div className="row justify-content-center mt-4">
            <div className="col col-lg-6 d-flex">
              <button
                type="button"
                className={
                  !formik.values.accountId
                    ? "btn btn-secondary"
                    : "btn btn-primary"
                }
                disabled={!formik.values.accountId || showLoader}
                onClick={() => launchStackHandler()}
              >
                Launch Stack
              </button>
              <img
                src={infoFilled}
                className="ml-2 pointer"
                onClick={() => setLunchStackModal(true)}
              />
            </div>
            <div className="col-lg-6">
              <span className="modal-subtext">
                Generate Role ARN by launching your Stack. Your Stack page will
                appear in a new window.
              </span>
            </div>
          </div>
          <hr></hr>
          <label className="label">Role ARN</label>
          <span className="mandatory-icon">*</span>
          <div className="row mt-2">
            <div className="col col-lg-12">
              <div className="row">
                <div className="col col-lg-12">
                  <input
                    type="text"
                    id="awsRoleARN"
                    placeholder="Cross-Account Role ARN"
                    name="awsRoleARN"
                    className="form-control"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "awsRoleARN",
                        e.target.value?.trim()
                      );
                      setEnableBtn(true);
                    }}
                    value={formik.values.awsRoleARN}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.awsRoleARN && formik.touched.awsRoleARN && (
                    <div className="error-message">
                      {formik.errors.awsRoleARN}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>{" "}
          {/* <hr></hr> */}
          {/* <div className="row ">
            <div className="col col-lg-3 d-flex justify-content-start align-items-center">
              <b className="form-field-name ">Scan Frequency </b>
            </div>
            <div className="col col-lg-9 p-0 d-flex justify-content-start align-items-center">
              <div className="row">
                <div className="col col-lg-3 p-0 d-flex justify-content-start align-items-center">
                  <span className="mr-4 radio-button-title">Default</span>{" "}
                  <input
                    type="radio"
                    value={FREQUENCY.DEFAULT}
                    name="frequency"
                    className="m-1 mr-3 default-radio-button"
                    onChange={(e) =>
                      formik.setFieldValue("scanFrequency", FREQUENCY.DEFAULT)
                    }
                    checked={formik.values.scanFrequency === FREQUENCY.DEFAULT}
                  />
                  <span className="radio-button-text">6hr</span>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="row mt-3">
            <div className="col col-lg-3 d-flex justify-content-start align-items-center"></div>
            <div className="col col-lg-9 p-0">
              <div className="row">
                <div className="col col-lg-12 p-0 d-flex justify-content-start align-items-start">
                  <span className="mr-4 radio-button-title">Custom</span>{" "}
                  <span className="mr-4 mt-n1">
                    <input
                      type="radio"
                      value={FREQUENCY.CUSTOM.FRQ_12_HR}
                      name="frequency"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "scanFrequency",
                          FREQUENCY.CUSTOM.FRQ_12_HR
                        )
                      }
                      checked={
                        formik.values.scanFrequency ===
                        FREQUENCY.CUSTOM.FRQ_12_HR
                      }
                      className="mr-3  custom-radio-button"
                    />{" "}
                    <br></br>
                    <span className="radio-button-text ml-n2"> 12hr</span>
                  </span>
                  <span className="mr-4 mt-n1">
                    <input
                      type="radio"
                      value={FREQUENCY.CUSTOM.FRQ_24_HR}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "scanFrequency",
                          FREQUENCY.CUSTOM.FRQ_24_HR
                        )
                      }
                      checked={
                        formik.values.scanFrequency ===
                        FREQUENCY.CUSTOM.FRQ_24_HR
                      }
                      name="frequency"
                      className="mr-3 ml-2 custom-radio-button"
                    />{" "}
                    <br></br>
                    <span className="radio-button-text"> 24hr</span>
                  </span>
                  <span className="mr-4 mt-n1">
                    <input
                      type="radio"
                      value={FREQUENCY.CUSTOM.FRQ_2_DAYS}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "scanFrequency",
                          FREQUENCY.CUSTOM.FRQ_2_DAYS
                        )
                      }
                      checked={
                        formik.values.scanFrequency ===
                        FREQUENCY.CUSTOM.FRQ_2_DAYS
                      }
                      name="frequency"
                      className="mr-3 ml-2 custom-radio-button"
                    />{" "}
                    <br></br>
                    <span className="radio-button-text"> 2days</span>
                  </span>
                  <span className="mr-4 mt-n1">
                    <input
                      type="radio"
                      value={FREQUENCY.CUSTOM.FRQ_5_DAYS}
                      onChange={(e) =>
                        formik.setFieldValue(
                          "scanFrequency",
                          FREQUENCY.CUSTOM.FRQ_5_DAYS
                        )
                      }
                      checked={
                        formik.values.scanFrequency ===
                        FREQUENCY.CUSTOM.FRQ_5_DAYS
                      }
                      name="frequency"
                      className="mr-3 ml-2 last-custom-radio-button"
                    />{" "}
                    <br></br>
                    <span className="ml-n1 radio-button-text"> 5days</span>
                  </span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="col mt-2 p-0">
              <div className="float-left">
                <button
                  type="button"
                  onClick={() => {
                    backStep(false);
                  }}
                  disabled={showLoader}
                  className="btn btn-primary"
                >
                  Back: Selct Account
                </button>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className={
                    !formik.isValid || !enableBtn
                      ? "btn btn-secondary mr-4"
                      : "btn btn-primary mr-4"
                  }
                  disabled={!formik.isValid || !enableBtn}
                >
                  Add Account
                </button>
                {/* <span class="vl"></span> */}
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleStepTwoModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {lunchStackModal ? (
        <InfoModal handleModal={() => setLunchStackModal(false)} />
      ) : (
        ""
      )}
    </div>
  );
};

export default AccountDetailStep;
