import { getUserId } from "../../utils/SessionHelper";
import DeleteIcon from "../../assets/img/delete.svg";

const Table = ({
  users,
  setCreateUserModal,
  deleteUser,
  updateRole,
  updateStatus,
}) => {
  return (
    <table className="mb-0 pb-3 table table-hover table-no-background">
      <thead className="vuls-row">
        <tr className="head vuls-row">
          <th scope="col">Email</th>
          <th scope="col">first Name</th>
          <th scope="col">Last Name</th>
          <th scope="col">Status</th>
          <th scope="col">Role</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => {
          return (
            <tr key={index} className="vuls-row">
              <td className="data-store-name">
                <div className="wrap-with-ellipsis">{user.email}</div>
              </td>
              <td className="table-data">
                <div className="wrap-with-ellipsis">{user.first_name}</div>
              </td>
              <td className="table-data">
                <div className="wrap-with-ellipsis">{user.last_name}</div>
              </td>
              <td className="table-type">
                <select
                  className="form-control"
                  value={user.status}
                  onChange={(e) =>
                    updateStatus(user._id, e.target.value, user.email)
                  }
                >
                  <option value={"IN-ACTIVE"}>PENDING</option>
                  <option value={"ACTIVE"}>ACTIVE</option>
                </select>
              </td>
              <td className="table-type">
                <select
                  className="form-control"
                  value={user.role}
                  onChange={(e) => updateRole(user._id, e.target.value)}
                >
                  <option value={"ADMIN"}>ADMIN</option>
                  <option value={"OPERATOR"}>OPERATOR</option>
                </select>
              </td>
              <td className="table-type">
                {user._id === getUserId() ? "" : () => user._id}
                <img
                  src={DeleteIcon}
                  className="ml-2 mr-2"
                  onClick={
                    user._id === getUserId() ? "" : () => deleteUser(user._id)
                  }
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
