import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { verifyAccountAsync } from "../../../redux/slices/accountSlice";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import "./AddAccountModal.scss";

const AccountVerifyStep = ({
  roleArn,
  accountId,
  addAccount,
  handleStepThreeModal,
  backStep,
}) => {
  const [isVerify, setIsVerify] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (roleArn && accountId) {
      // Verify Account API call
      verifyAccount();
    }
  }, [roleArn, accountId]);

  const verifyAccount = async () => {
    const resp = await dispatch(
      verifyAccountAsync({
        RoleArn: roleArn,
        id: accountId,
      })
    );
    if (resp && resp.payload && !resp.payload.err) {
      // Toaster(TOASTER_TYPES.SUCCESS, "Account verified successfully.");
      addAccount();
    } else {
      console.log("resp.payload.err: ", resp.payload.err);
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message || "Failed to verify account."
      );
      setIsVerify(false);
    }
  };

  return (
    <div>
      {isVerify ? (
        <div>
          <div className="modal-body">
            {" "}
            <img
              src="static/img/loader-circle.svg"
              className="modal-loading-img"
            ></img>
            <div className="row">
              <div className="col modal-verify-card ">
                <div className="">
                  <h4 className="modal-verify-title">
                    <b> Verifying AWS Account Connection</b>
                  </h4>
                  <hr className="hr-row"></hr>
                  <div className="row">
                    <div className="col-md-12 ml-5">
                      <span className="mr-2 modal-verify-subtext">
                        AWS ARN Number : <b>{roleArn}</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col mt-2">
                <div className="float-right">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleStepThreeModal(false)}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="verify-account">
          <div className="modal-body">
            <div className="row">
              <div className="col  modal-verify-card">
                <div className=" ">
                  <div className="text-center">
                    <img
                      src="static/img/mdi-alert-circle.svg"
                      className="mb-2"
                    />
                  </div>
                  <h4 className="modal-error-title">
                    <b> ERROR! Unable To Verify AWS Account</b>
                  </h4>
                  <hr className="hr-row"></hr>
                  <div className="row">
                    <div className="col-md-12 ml-5">
                      <span className="mr-2 modal-verify-subtext">
                        AWS ARN Number: <b>{roleArn}</b>
                      </span>
                    </div>
                  </div>

                  <div className="col mt-3 d-flex justify-content-center align-items-center ">
                    <span className="p-0 modal-error-subtext">
                      Please check the ARN. You can go back to the account
                      details by clicking on the BACK button below.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col mt-2">
                <div className="float-left">
                  <button
                    type="button"
                    onClick={() => backStep(false)}
                    className="btn btn-primary mr-2"
                  >
                    BACK: ACCOUNT DETAILS
                  </button>
                </div>
                <div className="float-right">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleStepThreeModal(false)}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountVerifyStep;
