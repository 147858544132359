import React, { useEffect, useState, useRef } from "react";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { useDispatch, useSelector } from "react-redux";
import scanningInheriting from "../../../assets/img/icons/scanning-inheriting.svg";
import scanningOverriding from "../../../assets/img/icons/scanning-overriding.svg";
import Aws from "../../../assets/img/integrations/aws.svg";
import Azure from "../../../assets/img/integrations/azure.svg";
import Gcp from "../../../assets/img/integrations/google.svg";
import loaderWhite from "../../../assets/img/half-circle-white.svg";
import Moment from "react-moment";
import ScanIcon from "../../../assets/img/icons/scan.svg";
import CreatedIcon from "../../../assets/img/icons/account-circle-fill.svg";
import CalendarIcon from "../../../assets/img/icons/calendar-check-fill.svg";
import Awsicon from "../../../assets/img/aws-account_card.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/img/edit.svg";
import { ReactComponent as ViewIcon } from "../../../assets/img/view.svg";
import { ReactComponent as CloseIcon } from "../../../assets/img/close-icon.svg";

import {
  listAccountsAsync,
  accountScanningStatus,
  revertUpdateStackAsync,
  verifyLaunchStackAsync,
  verifyUpdateStackAsync,
} from "../../../redux/slices/accountSlice";
import {
  hideLoading,
  showLoading,
} from "../../../redux/slices/globalOperationSlice";
import {
  ACCOUNT_TYPES,
  FREQUENCY,
  LOADER_CONFIG,
} from "../../../utils/Constants";
import { getCustomerId } from "../../../utils/SessionHelper";
import AddAccountModal from "../../modal/accounts-modal/AddAccountModal";
import DeleteAccountModal from "../../modal/accounts-modal/DeleteAccountModal";
import UpdateAccountModal from "../../modal/accounts-modal/UpdateAccountModal";

import "./account.scss";
import FirstTimeLoginAccount from "./FirstTimeLoginAccount";
import ViewAccountModal from "../../modal/accounts-modal/ViewAccountModal";

const Cards = () => {
  const [isModal, setIsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [cancelStack, setCancelStack] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);

  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [accountsListUpdated, setAccountsListUpdated] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const lastInterval = useRef();
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.account.accounts);
  const accountAvailable = useSelector(
    (state) => state.account.accountAvailable
  );
  const loading = useSelector((state) => state.globalOperation.loading);
  const refreshAccounts = async () => {
    dispatch(showLoading());
    setAccountList([]);
    await dispatch(listAccountsAsync());
    setIsModal(false);
    setAccountsLoaded(true);
    setDeleteModal(false);
    setUpdateModal(false);
    dispatch(hideLoading());
  };

  const getAccountTypeImage = (type) => {
    switch (type) {
      case ACCOUNT_TYPES.AWS:
        return Awsicon;
      case ACCOUNT_TYPES.GCP:
        return Gcp;
      case ACCOUNT_TYPES.AZURE:
        return Azure;
      default:
        return Aws;
    }
    return;
  };

  const getScanningFreq = (frequency) => {
    switch (frequency) {
      case FREQUENCY.DEFAULT:
      case FREQUENCY.CUSTOM.FRQ_12_HR:
      case FREQUENCY.CUSTOM.FRQ_24_HR:
        return scanningInheriting;
      case FREQUENCY.CUSTOM.FRQ_2_DAYS:
      case FREQUENCY.CUSTOM.FRQ_5_DAYS:
        return scanningOverriding;
      default:
        return scanningInheriting;
    }
    return;
  };

  const deleteAccount = (account) => {
    setSelectedAccount(account);
    setDeleteModal(true);
  };

  const updateAccount = (account) => {
    setSelectedAccount(account);
    setUpdateModal(true);
  };

  const viewAccount = (account) => {
    setSelectedAccount(account);
    setViewModal(true);
  };

  const setAccountStatus = async (account) => {
    dispatch(showLoading());
    let accountObj = { ...account };
    const resp = await dispatch(
      accountScanningStatus({
        customerId: getCustomerId(),
        cloudAccountId: account.id,
      })
    );
    if (resp && resp.payload && !resp.payload.err) {
      accountObj.firstClassificationComplete =
        resp.payload.first_classification_complete;
      accountObj.firstAcctRiskComplete = resp.payload.first_acct_risk_complete;
    } else {
      accountObj.firstClassificationComplete = null;
      accountObj.firstAcctRiskComplete = null;
    }
    dispatch(hideLoading());
    return accountObj;
  };

  const updateAccountStatus = async () => {
    let accountObjList = [];
    if (accountList.length > 0 && accounts.length > 0) {
      for (let index in accountList) {
        if (
          accountList[index].firstClassificationComplete == null ||
          accountList[index].firstAcctRiskComplete == null
        ) {
          const accountObj = await setAccountStatus(accountList[index]);
          accountObjList.push(accountObj);
        } else {
          accountObjList.push(accountList[index]);
        }
      }
      if (accountObjList.length > 0) {
        setAccountList(accountObjList);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (accounts.length > 0) {
      let accountObjList = [];
      for (let index in accounts) {
        const accountObj = await setAccountStatus(accounts[index]);
        accountObjList.push(accountObj);
      }
      setAccountList(accountObjList);
    } else {
      setAccountsListUpdated(false);
      setAccountList([]);
    }
    setAccountsLoaded(true);
  }, [accounts]);

  useEffect(() => {
    if (accountList.length > 0) {
      // update staus after every 30 sec
      if (!lastInterval.current) {
        lastInterval.current = setInterval(() => {
          updateAccountStatus();
        }, 30000);
      }
    }
    return () => {
      // clean up
      if (accountList.length === 0) {
        clearInterval(lastInterval?.current);
      }
    };
  }, [accountList]);

  const makePolling = async (id) => {
    const resp = await dispatch(
      verifyLaunchStackAsync({
        id: id,
      })
    );
    if (resp && resp.payload) {
      refreshAccounts();
    }
  };
  const makeUpdatePolling = async (id) => {
    const resp = await dispatch(
      verifyUpdateStackAsync({
        id: id,
      })
    );
    if (resp && resp.payload) {
      refreshAccounts();
      setCancelStack(false);
      setEditDisabled(false);
    } else {
      setCancelStack(true);
    }
  };

  const revertUpdateStack = async (id) => {
    const resp = await dispatch(
      revertUpdateStackAsync({
        id: id,
        revert: true,
      })
    );
    if (resp && resp.payload) {
      refreshAccounts();
      setCancelStack(false);
      setEditDisabled(false);
    }
  };
  useEffect(() => {
    const intervalCall = setInterval(() => {
      for (let i of accountList) {
        if (i.status === "creating_stack") {
          makePolling(i.id);
        }
        if (i.status === "updating_stack") {
          setCancelStack(true);
          makeUpdatePolling(i.id);
        }
      }
    }, 30000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [accountList]);

  useEffect(() => {
    if (accounts.length === 0) {
      refreshAccounts();
    }
    return () => {
      // clean up
      setAccountList([]);
      clearInterval(lastInterval?.current);
    };
  }, []);

  return (
    <BlockUi
      tag="div"
      blocking={loading}
      loader={
        <Loader active type={LOADER_CONFIG.type} color={LOADER_CONFIG.color} />
      }
    >
      <div className="account-container">
        <div className="account-title px-3 mb-2">
          <div class="row mb-3 mt-1 ">
            <div class="col col-xl-12 align-self-end">
              <span>ACCOUNTS</span>
            </div>
          </div>
        </div>
        <div className="px-3 mb-2">
          <button
            className="btn btn-info create-btn"
            onClick={() => setIsModal(true)}
          >
            {/* <i class="mdi mdi-menu "></i>{" "} */}

            <img
              alt=""
              src="static/img/add-account.svg"
              className="add-account-svg"
            />
            <b>Add New Account</b>
          </button>
        </div>
        <div className="account px-3 pt-2">
          <div className="row">
            <div className="col-md-10">
              <div className="row">
                {accountList &&
                  accountList
                    .sort((a, b) => a - b)
                    .map((account, index) => (
                      <div
                        key={account._id}
                        className="col-sm-6 col-md-6 col-lg-4 mb-3 account-card"
                      >
                        <div className="">
                          <div className="account-details iq-card iq-card-inner p-2">
                            <div className="m-0 row">
                              <div className="col-2 pt-1 text-center p-0 check ">
                                {account?.firstAcctRiskComplete &&
                                account?.firstClassificationComplete ? (
                                  <img src="static/img/mdi-check-circle.svg" />
                                ) : (
                                  <img src={loaderWhite} width="24px" />
                                )}
                              </div>
                              <div className="col-10 card-heading">
                                <div className="row">
                                  <div className="col-6">
                                    {" "}
                                    <h6 className="card-title">
                                      {account?.id || ""}
                                    </h6>
                                  </div>{" "}
                                  <div className="col-6 d-flex justify-content-end align-items-center">
                                    {/* Hiding this as it is not in work at the moment */}
                                    {/* <img
                                      src={getScanningFreq(
                                        account.scanFrequency
                                      )}
                                    ></img>{" "}
                                    <span className="card-time">
                                      {account.scanFrequency}
                                    </span> */}

                                    <ViewIcon
                                      title="View"
                                      onClick={() => viewAccount(account)}
                                      className="card-action-images"
                                    />

                                    {cancelStack ? (
                                      <CloseIcon
                                        title="Edit"
                                        onClick={() =>
                                          revertUpdateStack(account.id)
                                        }
                                        className="card-action-images"
                                      />
                                    ) : (
                                      <EditIcon
                                        title="Edit"
                                        onClick={
                                          editDisabled
                                            ? ""
                                            : () => updateAccount(account)
                                        }
                                        className={
                                          editDisabled
                                            ? "card-action-images card-action-images-disabled"
                                            : "card-action-images"
                                        }
                                      />
                                    )}

                                    <DeleteIcon
                                      title="Delete"
                                      onClick={() => deleteAccount(account)}
                                      className="card-action-images"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="d-flex mx-3 mb-2 justify-content-between info">
                                <div>
                                  <img
                                    src={CalendarIcon}
                                    className="mr-1"
                                  ></img>
                                  <Moment format="YYYY-MM-DD">
                                    {account?.metadata?.createdAt}
                                  </Moment>
                                </div>
                                {/* <div>
                                  <img src={ScanIcon} className="mr-1"></img>
                                  <Moment fromNow>
                                    {account?.firstAcctRiskComplete}
                                  </Moment>
                                </div> */}
                              </div>
                              <ul>
                                <li className="d-flex justify-content-start align-items-center">
                                  <i className="ri-checkbox-blank-circle-fill mr-2 account-icon"></i>
                                  {!account?.firstClassificationComplete
                                    ? "Discovering & Analyzing data store contents..."
                                    : !account?.firstAcctRiskComplete
                                    ? "Identifying issues & attack paths..."
                                    : account?.accountDescription
                                    ? account?.accountDescription
                                    : ""}
                                </li>
                              </ul>
                            </div>
                            <div className="footer mx-1">
                              <div className="text-center">
                                <img
                                  className={"footer-icons my-2"}
                                  src={getAccountTypeImage(account.type)}
                                />
                              </div>
                              <div>
                                {/* <img
                              alt=""
                              src="static/img/edit.svg"
                              className="card-action-images"
                              title="Edit"
                            ></img> */}
                                {/* <span className="created-by">
                                  <img src={CreatedIcon} className="mr-1"></img>
                                  {account?.userId?.username}
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
        {isModal && (
          <AddAccountModal
            handleModal={() => {
              setIsModal(false);
            }}
            refreshAccounts={refreshAccounts}
          />
        )}

        {deleteModal && selectedAccount && (
          <DeleteAccountModal
            handleModal={() => {
              setDeleteModal(false);
            }}
            refreshAccounts={refreshAccounts}
            account={selectedAccount}
          />
        )}
        {updateModal && selectedAccount && (
          <UpdateAccountModal
            handleModal={() => {
              setUpdateModal(false);
            }}
            refreshAccounts={refreshAccounts}
            account={selectedAccount}
            setEditDisabled={setEditDisabled}
          />
        )}

        {viewModal && selectedAccount && (
          <ViewAccountModal
            handleModal={() => {
              setViewModal(false);
            }}
            refreshAccounts={refreshAccounts}
            account={selectedAccount}
          />
        )}
      </div>
    </BlockUi>
  );
};

export default Cards;
