import "./DeleteAccountModal.scss";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import deleteAccountIcon from "../../../assets/img/icons/delete-account-icon.svg";
import popout from "../../../assets/img/icons/popout-white.svg";
import {
  deleteAccountAsync,
  deleteStackAsync,
  verifyDeleteStackAsync,
} from "../../../redux/slices/accountSlice";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import {
  showLoading,
  hideLoading,
} from "../../../redux/slices/globalOperationSlice";

// Functional component for Add Account.
const DeleteAccountModal = ({ handleModal, refreshAccounts, account }) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [disableDelete, setDisableDelete] = useState(true);
  const [launchStackClicked, setLaunchStackClicked] = useState(false);

  const verifyAccountTimer = useRef();
  const removeSessionStorage = (accountId) => {
    let issuesAccountFilter = JSON.parse(
      sessionStorage.getItem("issuesAccountFilter")
    );
    let datastoreAccountFilter = JSON.parse(
      sessionStorage.getItem("datastoreAccountFilter")
    );
    if (
      issuesAccountFilter &&
      issuesAccountFilter !== null &&
      issuesAccountFilter.value == accountId
    ) {
      sessionStorage.removeItem("issuesAccountFilter");
    }
    if (
      datastoreAccountFilter &&
      datastoreAccountFilter !== null &&
      datastoreAccountFilter.value == accountId
    ) {
      sessionStorage.removeItem("datastoreAccountFilter");
    }
  };

  const deleteAccount = async () => {
    setShowLoader(true);
    const resp = await dispatch(deleteAccountAsync(account.id));
    if (resp && resp.payload && !resp.payload.err) {
      refreshAccounts();
      setShowLoader(false);
      Toaster(TOASTER_TYPES.SUCCESS, "Account deleted successfully.");
      removeSessionStorage(account.id);
    } else {
      setShowLoader(false);
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message || "Failed to delete account."
      );
    }
  };

  const deleteStack = async () => {
    setShowLoader(true);
    const resp = await dispatch(deleteStackAsync({ id: account.id }));
    if (resp && resp.payload?.url && !resp.payload.err) {
      window.open(resp.payload?.url);
      verifyAccountTimer.current = setInterval(() => {
        verifyDeleteStack();
      }, 5000);
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload.err?.message || "Failed to launch delete stack."
      );
    }
  };

  const handleLaunchStack = () => {
    deleteStack();
    setLaunchStackClicked(true);
  };

  const verifyDeleteStack = async () => {
    const resp = await dispatch(
      verifyDeleteStackAsync({
        RoleArn: account.awsRoleARN,
      })
    );
    if (resp && resp.payload) {
      setShowLoader(false);
      clearInterval(verifyAccountTimer?.current);
      setDisableDelete(false);
      setLaunchStackClicked(false);
    } else {
      if (verifyAccountTimer && launchStackClicked) {
        setShowLoader(true);
      }
    }
  };

  const handleCancel = () => {
    clearInterval(verifyAccountTimer?.current);
    handleModal(false);
  };

  useEffect(() => {
    verifyDeleteStack();
    return () => {
      clearInterval(verifyAccountTimer?.current);
    };
  }, []);

  return (
    <div id="delete-account">
      <div
        className="modal fade show"
        id="deleteModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deleteModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-start align-items-center">
              <img src={deleteAccountIcon} className="mr-3" />
              <span>Delete Account</span>
            </div>
            <div className="modal-body">
              {showLoader && (
                <img
                  src="static/img/loader-circle.svg"
                  className="modal-loading-img"
                />
              )}
              <div className="row">
                <div className="col-md-3"> Account Id:</div>
                <div className="col-md-8">{account.id}</div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3"> External ID:</div>
                <div className="col-md-8">{account.externalId}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-3"> AWS Role ARN:</div>
                <div className="col-md-8">{account.awsRoleARN}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  Delete stack is mandatory for deleting the account. Please
                  click on launch delete stack button to delete the stack.
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary btn-sm"
                onClick={handleLaunchStack}
              >
                Launch delete stack <img src={popout} className="popout-img" />
              </button>
              <span className="separator mx-3"></span>
              <button
                className="btn btn-primary btn-sm"
                disabled={disableDelete}
                onClick={deleteAccount}
              >
                delete account
              </button>
              <span className="separator mx-3"></span>
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
