import "./AddAccountModal.scss";
import React from "react";
import { accountTypes } from "../../../utils/accountsHelper";
import { ACCOUNT_TYPES } from "../../../utils/Constants";

const SelectAccountStep = ({
  handleStepOneModal,
  handleSelected,
  selected,
  nextStep,
}) => {
  const getClassNameForSelection = (accountType) => {
    return `${
      selected === accountType ? "modal-card-selected" : "modal-card"
    } text-center`;
  };

  return (
    <>
      <div className="modal-body">
        <div className="row mb-5">
          <div className="col d-flex justify-content-center align-items-center flex-wrap">
            {accountTypes.map((account, index) => (
              <div
                onClick={() =>
                  account.type == ACCOUNT_TYPES.AWS
                    ? handleSelected(account.type)
                    : null
                }
                className={getClassNameForSelection(account.type)}
                key={index}
                style={
                  account.type !== ACCOUNT_TYPES.AWS
                    ? { opacity: 0.7, cursor: "default" }
                    : {}
                }
              >
                <img src={account.image} className="modal-card-img mt-4 mb-2" />
              </div>
            ))}
          </div>
        </div>
        <div className="float-right mt-5">
          <button
            type="button"
            onClick={() => {
              nextStep();
            }}
            className="btn btn-primary mr-4"
          >
            NEXT: ACCOUNT INFO
          </button>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              handleStepOneModal(false);
            }}
          >
            CANCEL
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectAccountStep;
