import React, { useState } from "react";
import { getCustomerId } from "../../../utils/SessionHelper";
import "./../modal.scss";
import "./AffectedDatastoresAndIssuesModal.scss";
import { getImpactedDatastores } from "../../../apis";
import { addIndex } from "../../../utils/dataHandler";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Pagination from "../../pagination/Pagination";
import "react-toggle/style.css"; // for ES6 modules
import NoDataContainer from "../../cards/no-data";
import { LOADER_CONFIG } from "../../../utils/Constants";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import closeIcon from "../../../assets/img/icons/close-icon.svg";
import closeIconGray from "../../../assets/img/icons/close-icon-grey.svg";
import { formatHeader } from "../../../utils/uppercase";
import DatastoreIssues from "../../datastore-issues/DatastoreIssues";
import ImpactedDatastore from "../../impacted-datastores/ImpactedDatastore";
const AffectedDatastoresAndIssuesModal = ({
  handleModal,
  assetIdValue,
  isAttackPath,
  node,
  page = "",
  selectedTab,
  isSecurityIssueVisisble = false,
  isImpactedDatastoreVisible = false,
}) => {
  const [loading, setLoadingStatus] = useState(false);

  let limit = 10;

  return (
    <div id="affected-datastore-and-issue-modal">
      <div
        className="modal fade show"
        id="info"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="infoModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <BlockUi
            tag="div"
            blocking={loading}
            loader={
              <Loader
                active
                type={LOADER_CONFIG.type}
                color={LOADER_CONFIG.color}
              />
            }
          >
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-between align-items-center">
                <span className="header-title">
                  {isSecurityIssueVisisble
                    ? isAttackPath && node
                      ? selectedTab === "access"
                        ? node?.userName
                        : node?.name
                      : "Security Issues"
                    : ""}
                  {isImpactedDatastoreVisible
                    ? isAttackPath && node
                      ? node?.name
                      : "Impacted Datastores"
                    : ""}
                </span>
                <img
                  className="close-icon cursor-pointer"
                  src={closeIconGray}
                  title="Close"
                  onClick={handleModal}
                  width="25px"
                />
              </div>
              <div className="modal-body mb-2">
                {isSecurityIssueVisisble && (
                  <DatastoreIssues
                    assetIdValue={assetIdValue}
                    isAttackPath={isAttackPath}
                    node={node}
                    loading={loading}
                    pageName={page}
                    selectedTab={selectedTab}
                    setLoading={(val) => setLoadingStatus(val)}
                  />
                )}
                {isImpactedDatastoreVisible && (
                  <ImpactedDatastore
                    assetIdValue={assetIdValue}
                    isAttackPath={isAttackPath}
                    node={node}
                    loading={loading}
                    setLoading={(val) => setLoadingStatus(val)}
                  />
                )}
              </div>
            </div>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default AffectedDatastoresAndIssuesModal;
