import { axiosDenaliPrivate, axiosPrivate } from "../../utils/ApiHandler";
import { getCustomerId } from "../../utils/SessionHelper";

/**
 * Provides the list of all accounts.
 * @returns -- Accounts list
 */
export const fetchAccounts = () => {
  return axiosPrivate.get(
    `/cloudAccount/getCloudAccountForSpecificUser/${getCustomerId()}`
  );
};

/**
 * Provides the list of all accounts.
 * @returns -- Accounts list
 */

export const fetchAccountStatus = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/accounts/status`, params);
};

/**
 * Verify the selected account.
 * @param {object} params -- payload params.
 * @returns
 */
export const verifyAccount = (params) => {
  return axiosPrivate.post(
    `/cloudAccount/cloudAccount/verifyCloudAccount`,
    params
  );
};

/**
 * Provides the url for the stack details.
 * @returns The url for the stack.
 */
export const launchStack = (params) => {
  return axiosPrivate.post(`/cloudAccount/cloudAccount/launchStack`, params);
};

/**
 * Add the accounts with the specified account details.
 * @param {object} params -- payload params
 * @returns the account details added.
 */
export const addAccount = (params) => {
  return axiosPrivate.post(`/cloudAccount/cloudAccount`, params);
};

/**
 * update the account with the specified account details.
 * @param {object} params -- payload params
 * @returns the account details added.
 */
export const updateStack = (params) => {
  return axiosPrivate.post(`/cloudAccount/cloudAccount/updateStack`, params);
};

/**
 * Provides the list of all accounts.
 * @returns -- Accounts list
 */
export const generateExternalId = () => {
  return axiosPrivate.get(`/cloudAccount/generateExternalId`);
};

/**
 * Provides the list of all accounts.
 * @returns -- Accounts list
 */
export const getRegionList = () => {
  return axiosPrivate.post(`/cloudAccount/cloudAccount/regionListing`);
};

/**
 * Delete the account with the specified account Id.
 * @param {object} accountId -- account Id to be deleted
 */
export const deleteAccount = (accountId) => {
  return axiosPrivate.delete(`/cloudAccount/cloudAccount/${accountId}`);
};

/**
 *  Provides the url for the delete stack details.
 * @param {object} params -- payload params
 */
export const deleteStack = (params) => {
  return axiosPrivate.post(`/cloudAccount/cloudAccount/deleteStack`, params);
};

/**
 * Verify delete stack for the selected account.
 * @param {object} params -- payload params.
 * @returns
 */
export const verifyDeleteStack = (params) => {
  return axiosPrivate.post(
    `/cloudAccount/cloudAccount/verifyDeleteStack`,
    params
  );
};

/**
 * Verify delete stack for the selected account.
 * @param {object} params -- payload params.
 * @returns
 */
export const verifyUpdateStack = (params) => {
  return axiosPrivate.post(`/cloudAccount/verifyUpdateStack`, params);
};

/**
 * Verify delete stack for the selected account.
 * @param {object} params -- payload params.
 * @returns
 */
export const verifyLaunchStack = (params) => {
  return axiosPrivate.post(`/cloudAccount/verifyLaunchStack`, params);
};

/**
 * Verify delete stack for the selected account.
 * @param {object} params -- payload params.
 * @returns
 */
export const revertUpdateStack = (params) => {
  return axiosPrivate.post(`/cloudAccount/revertUpdateStack`, params);
};

/**
 * provide the account status.
 * @param {object} params -- payload params.
 * @returns
 */
export const fetchAccountScanningStatus = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/account/get`, params);
};
