import React, { useEffect } from "react";
import DetailsContainer from "../../components/datastore-details/DetailsContainer";

// import Resources from "../../components/connect/resources";

const Datastore = ({ setPath, setTicket }) => {
  useEffect(() => {
    document.title = "Cloud Defense - Datastore Details";
    setPath(window.location.pathname);
  }, []);

  return (
    <>
      <DetailsContainer setTicket={setTicket} />
    </>
  );
};

export default Datastore;
