import "./UpdateAccountModal.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import loaderImage from "../../../assets/img/loader.svg";
import {
  updateAccountAsync,
  updateStackAsync,
  verifyUpdateStackAsync,
} from "../../../redux/slices/accountSlice";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import {
  showLoading,
  hideLoading,
} from "../../../redux/slices/globalOperationSlice";

// Functional component for Add Account.
const UpdateAccountModal = ({
  handleModal,
  refreshAccounts,
  account,
  setEditDisabled,
}) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [stackId, setStackId] = useState("");
  const [selectedVersion, setSelectedVersion] = useState({
    value: "0.0.2",
    label: "0.0.2",
  });

  const DATA_SCANNING_FREQUENCY = [
    { label: "Daily", value: "rate(1 day)" },
    { label: "Weekly", value: "rate(7 days)" },
    { label: "Monthly", value: "rate(30 days)" },
  ];

  const [dataScanFrequency, setDataScanFrequency] = useState();

  const updateStack = async () => {
    setShowLoader(true);
    dispatch(showLoading());
    const resp = await dispatch(
      updateStackAsync({
        id: account.id,
        stackId: stackId,
        dataScanFrequency: dataScanFrequency,
      })
    );
    dispatch(hideLoading());
    if (resp?.payload?.update && !resp?.payload?.err) {
      setShowLoader(false);
      setEditDisabled(true);
      window.open(resp.payload?.update);
      refreshAccounts(); // TODO: Refresh accounts page if need be.
      handleModal(false);
    } else {
      setShowLoader(false);
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload.err?.message || "Failed to update account."
      );
    }
  };

  const handleLaunchStack = () => {
    updateStack();
  };

  const handleCancel = () => {
    handleModal(false);
  };

  const versions = [
    { value: "0.0.1", label: "0.0.1" },
    { value: "0.0.2", label: "0.0.2" },
  ];

  useEffect(() => {
    if (
      account?.dataScanFrequency === "Monthly" ||
      account?.dataScanFrequency === "rate(30 days)"
    ) {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[2].value);
    } else if (
      account?.dataScanFrequency === "Weekly" ||
      account?.dataScanFrequency === "rate(7 days)"
    ) {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[1].value);
    } else if (
      account?.dataScanFrequency === "Daily" ||
      account?.dataScanFrequency === "rate(1 day)"
    ) {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[0].value);
    } else {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[0].value);
    }
  }, [account]);

  return (
    <div id="update-account">
      <div
        className="modal fade show"
        id="updateModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="updateModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
            <div className="modal-header pl-0 d-flex justify-content-start align-items-center">
              {/* <img src={updateAccountIcon} className="mr-3" /> */}
              <span>Update Account Stack</span>
            </div>
            <div className="modal-body">
              {showLoader && (
                <img src={loaderImage} className="modal-loading-img" />
              )}
              <div className="row">
                <div className="col-md-3 pl-0"> Account ID:</div>
                <div className="col-md-8 modal-text">{account.id}</div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3 pl-0"> External ID:</div>
                <div className="col-md-8 modal-text">{account.externalId}</div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3 pl-0"> AWS Role ARN:</div>
                <div className="col-md-8 modal-text">{account.awsRoleARN}</div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3 pl-0"> Stack Id:</div>
                <div className="col-md-9 pr-0">
                  {/* <Select
                    name="versions"
                    options={versions}
                    value={selectedVersion}
                    className="versions-dropdown"
                    classNamePrefix="versions-dropdown"
                    onChange={(value) => {
                      setSelectedVersion(value);
                    }}
                  /> */}
                  <input
                    type="text"
                    className="form-control"
                    value={stackId}
                    onChange={(e) => setStackId(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-3 pl-0"> Data Scanning Frequency:</div>
                <div className="col-md-9 pr-0">
                  {/* <Select
                    name="versions"
                    options={DATA_SCANNING_FREQUENCY}
                    value={scanningFrequency}
                    className="versions-dropdown"
                    classNamePrefix="versions-dropdown"
                    onChange={(value) => {
                      setDataScanFrequency(value);
                    }}
                  /> */}
                  <select
                    className="form-control"
                    value={dataScanFrequency}
                    onChange={(event) => {
                      setDataScanFrequency(event.target.value);
                    }}
                  >
                    <option disabled>Select Data Scanning Frequency</option>
                    {DATA_SCANNING_FREQUENCY.map((frequency, index) => (
                      <option key={index} value={frequency.value}>
                        {frequency.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row modal-message mt-4 pt-4">
                <div className="col-md-12 pl-0">
                  Please click on update stack button to update the stack.
                </div>
              </div>
            </div>
            <div className="modal-footer pb-4 pt-0">
              <button
                className={
                  showLoader
                    ? "btn btn-primary update-stack-disabled btn-sm"
                    : "btn btn-primary btn-sm"
                }
                onClick={handleLaunchStack}
                disabled={!stackId || showLoader}
              >
                Update stack
              </button>
              <span className="separator mx-3"></span>
              {/* <button
                className="btn btn-primary btn-sm"
                disabled={disableUpdate}
                onClick={updateAccount}
              >
                update account
              </button> */}
              {/* <span className="separator mx-3"></span> */}
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAccountModal;
