import "./account.scss";
import React, { useState, useEffect } from "react";
import {
  ACCOUNT_TYPES,
  WIZARD_STEPS,
  FREQUENCY,
} from "../../../utils/Constants";
import SelectAccountStep from "./SelectAccountStep";
import AccountDetailStep from "./AccountDetailStep";
import {
  addAccountAsync,
  generateExternalIdAsync,
  verifyAccountAsync,
  getRegionListAsync,
} from "../../../redux/slices/accountSlice";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import {
  showLoading,
  hideLoading,
} from "../../../redux/slices/globalOperationSlice";
import { getUserId, getCustomerRef } from "../../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const FirstTimeLoginAccount = ({}) => {
  const [selectedAccountType, setSelectedAccountType] = useState();
  const [currentStep, setCurrentStep] = useState(WIZARD_STEPS.SELECT_ACCOUNT);
  const [externalId, setExternalId] = useState("");
  const [accountDetails, setAccountDetails] = useState({
    accountId: "",
    accountDescription: "",
    externalId: "",
    awsRoleARN: "",
    dataScanFrequency: FREQUENCY.DEFAULT,
  });
  const [showLoader, setShowLoader] = useState(false);

  const [regionList, setRegionList] = useState([]);
  let history = useHistory();

  const dispatch = useDispatch();

  const generateExtId = async () => {
    const resp = await dispatch(generateExternalIdAsync());
    if (resp && resp.payload?.ExternalId) {
      setExternalId(resp.payload?.ExternalId);
    }
  };

  const verifyAccount = async (values) => {
    setShowLoader(true);
    const resp = await dispatch(
      verifyAccountAsync({
        RoleArn: values.awsRoleARN,
        id: values.accountId,
      })
    );
    setShowLoader(false);
    if (resp && resp.payload && !resp.payload.err) {
      addAccount(values);
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        resp?.payload?.err?.message ||
          "Failed to verify account. Please check the details and try again."
      );
    }
  };

  const addAccount = async (values) => {
    setShowLoader(true);
    const resp = await dispatch(
      addAccountAsync({
        id: values.accountId,
        accountDescription: values.accountDescription,
        awsRoleARN: values.awsRoleARN,
        dataScanFrequency: values.dataScanFrequency,
        scanFrequency: FREQUENCY.DEFAULT,
        externalId: values.externalId,
        userId: getUserId(),
        type: selectedAccountType,
        customerRef: getCustomerRef(),
      })
    );
    if (resp && resp.payload && !resp.payload.err) {
      setShowLoader(false);

      Toaster(
        TOASTER_TYPES.SUCCESS,
        "Account verified and added successfully."
      );
      history.push("/account");
    } else {
      setShowLoader(false);

      Toaster(
        TOASTER_TYPES.ERROR,
        resp?.payload?.err?.message ||
          "Failed to add account. Please check the details and try again"
      );
    }
  };

  const getRegionList = async () => {
    const resp = await dispatch(getRegionListAsync());
    var respList = resp.payload?.msg;
    var updatedRegionList = [];
    respList.map((val) => {
      updatedRegionList.push({ label: val, value: val });
    });
    if (resp && resp.payload?.msg) {
      setRegionList(updatedRegionList);
    }
  };

  useEffect(() => {
    generateExtId();
    getRegionList();
  }, []);

  return (
    <div className="add-account-container">
      <div className="header">Welcome to Cloud Defense</div>
      <div className="sub-header-text">
        {/* Vestibulum iusto nonummy nostra diam ullamcorper. Diam usitas dolore
        sociis paratus reprobo rhoncus. Ultrices eros luptatum exerci erat
        accumsan consequat nullam. Porta ymo letalis eligo camur class eum fere.
        Sodales macto luctus capio epulae saepius molior. Vehicula et plaga
        iusto vero. */}
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-7">
          <div className="add-account-content mx-3">
            <div className="content-header d-flex align-items-center mb-4">
              Add new account
            </div>

            <div className="steps-header mb-4">
              <ul>
                <li className={currentStep >= 1 ? "activestep" : ""}>
                  <div className="step-number">1</div>
                  <div className="step-text">Select the type of account</div>
                </li>
                <li className={currentStep == 2 ? "activestep" : ""}>
                  <div className="step-number">2</div>
                  <div className="step-text">Enter account details</div>
                </li>
              </ul>
            </div>
            {currentStep === WIZARD_STEPS.SELECT_ACCOUNT && (
              <SelectAccountStep
                selected={selectedAccountType}
                handleSelected={(val) => {
                  setSelectedAccountType(val);
                  setCurrentStep(currentStep + 1);
                }}
                currentStep={currentStep}
              />
            )}
            {currentStep === WIZARD_STEPS.ACCOUNT_DETAILS && (
              <AccountDetailStep
                accountDetails={accountDetails}
                setAccountDetails={setAccountDetails}
                externalId={externalId}
                backStep={() => {
                  setCurrentStep(currentStep - 1);
                }}
                addAccount={verifyAccount}
                currentStep={currentStep}
                regionList={regionList}
                showLoader={showLoader}
                setShowLoader={setShowLoader}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstTimeLoginAccount;
